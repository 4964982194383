import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'; // Importando ícones
import styles from './Footer.module.css'; // Importando o CSS Module

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p>&copy; 2024 ZFW Engenharia. Todos os direitos reservados.</p>
        <p>
          <FaMapMarkerAlt className={styles.icon} /> 
          Rua República da China, 80, Bairro Floresta, Joinville, Santa Catarina
        </p>
        <p>
          <FaPhoneAlt className={styles.icon} /> 
          Telefone: +55 47 3473 7029
        </p>
      </div>
    </footer>
  );
}


export default Footer;
