import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';

function Home() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    document.title = "ZFW Engenharia - Projetos, Softwares Industriais, Painéis Elétricos | Integração TI x TA | Automação Industrial | Dispositivos Mecânicos Automatizados";
  
    const carouselElement = document.getElementById('carouselExampleIndicators');
  
    // Inicializar manualmente o carrossel sem atribuir à variável
    new window.bootstrap.Carousel(carouselElement, {
      interval: 3000, // Tempo entre slides
      ride: 'carousel' // Definir como automático
    });
  
    const handleSlideChange = () => {
      const carouselItems = document.querySelectorAll('.carousel-item');
      carouselItems.forEach((item, index) => {
        if (item.classList.contains('active')) {
          setActiveIndex(index);
        }
      });
    };
  
    carouselElement.addEventListener('slid.bs.carousel', handleSlideChange);
  
    return () => {
      carouselElement.removeEventListener('slid.bs.carousel', handleSlideChange);
    };
  }, []);
  
  
  return (
    <div className="home-container d-flex flex-column min-vh-100">
      <main className="flex-grow-1">
        {/* Banner Rotativo */}
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="3000"
        >
          <div className="carousel-inner">
            {/* Primeiro Banner */}
            <div className="carousel-item active">
              <div className="d-flex justify-content-center banner-container">
                <img src="/images/painel-automacao1.png" className="banner-image" alt="Painel Automação 1" />
                <img src="/images/painel-automacao2.png" className="banner-image" alt="Painel Automação 2" />
                <img src="/images/painel-automacao3.png" className="banner-image" alt="Painel Automação 3" />
              </div>
            </div>

            {/* Segundo Banner */}
            <div className="carousel-item">
              <div className="d-flex justify-content-center banner-container">
                <img src="/images/painel-eletrico1.png" className="banner-image" alt="Painel Elétrico 1" />
                <img src="/images/painel-eletrico2.png" className="banner-image" alt="Painel Elétrico 2" />
                <img src="/images/painel-eletrico3.png" className="banner-image" alt="Painel Elétrico 3" />
              </div>
            </div>

            {/* Terceiro Banner */}
            <div className="carousel-item">
              <div className="d-flex justify-content-center banner-container">
                <img src="/images/adequacao-eletrica1.png" className="banner-image" alt="Adequação Elétrica 1" />
                <img src="/images/adequacao-eletrica2.png" className="banner-image" alt="Adequação Elétrica 2" />
                <img src="/images/adequacao-eletrica3.png" className="banner-image" alt="Adequação Elétrica 3" />
              </div>
            </div>

            {/* Quarto Banner */}
            <div className="carousel-item">
              <div className="d-flex justify-content-center banner-container">
                <img src="/images/adequacao-seguranca1.png" className="banner-image" alt="Adequação Segurança 1" />
                <img src="/images/adequacao-seguranca2.png" className="banner-image" alt="Adequação Segurança 2" />
                <img src="/images/adequacao-seguranca3.png" className="banner-image" alt="Adequação Segurança 3" />
              </div>
            </div>

            {/* Quinto Banner */}
            <div className="carousel-item">
              <div className="d-flex justify-content-center banner-container">
                <img src="/images/reforma-equipamentos1.png" className="banner-image" alt="Reforma Equipamentos 1" />
                <img src="/images/reforma-equipamentos2.png" className="banner-image" alt="Reforma Equipamentos 2" />
                <img src="/images/reforma-equipamentos3.png" className="banner-image" alt="Reforma Equipamentos 3" />
              </div>
            </div>

            {/* Sexto Banner */}
            <div className="carousel-item">
              <div className="d-flex justify-content-center banner-container">
                <img src="/images/retrofitting1.png" className="banner-image" alt="Retrofitting 1" />
                <img src="/images/retrofitting2.png" className="banner-image" alt="Retrofitting 2" />
                <img src="/images/retrofitting3.png" className="banner-image" alt="Retrofitting 3" />
              </div>
            </div>
          </div>

          {/* Botões de Controle */}
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        {/* Faixa Inferior (Verde, Branco e Azul) */}
        <div className="banner-borders">
          <div className="green-border"></div> {/* Faixa Verde Abaixo */}
          <div className="white-border"></div> {/* Borda Branca */}
          <div className="blue-border"></div> {/* Faixa Azul Abaixo */}
        </div>

        {/* Indicadores Circulares Abaixo da Borda Azul */}
        <div className="indicators-container d-flex justify-content-center my-4">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className={`carousel-indicator ${activeIndex === 0 ? 'active' : ''}`}
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            className={`carousel-indicator ${activeIndex === 1 ? 'active' : ''}`}
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            className={`carousel-indicator ${activeIndex === 2 ? 'active' : ''}`}
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            className={`carousel-indicator ${activeIndex === 3 ? 'active' : ''}`}
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            className={`carousel-indicator ${activeIndex === 4 ? 'active' : ''}`}
            aria-label="Slide 5"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="5"
            className={`carousel-indicator ${activeIndex === 5 ? 'active' : ''}`}
            aria-label="Slide 6"
          ></button>
        </div>

        {/* Frase Impactante */}
        <div className="impact-phrase container text-center my-5">
          <h2>Excelência em Automação Industrial, Montagem de Painéis Elétricos, Segurança Operacional e Integração de Sistemas TI x TA.</h2>
          <p>Soluções Inovadoras para Transformar e Otimizar Seus Processos.</p>
        </div>

        {/* Seção de Soluções */}
        <section className="solucoes-section py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="solucoes-card text-center">
                  <h4>Montagem de Painéis Elétricos</h4>
                  <img src="/images/painel-automacao1.png" alt="Montagem de Painéis Elétricos" className="img-fluid rounded-image" />
                  <p>Montagem de painéis e quadros elétricos com qualidade e precisão.</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="solucoes-card text-center">
                  <h4>Projetos Elétricos</h4>
                  <img src="/images/projeto-eletrico1.png" alt="Projetos Elétricos" className="img-fluid rounded-image" />
                  <p>Desenvolvimento de projetos elétricos atendendo normas internacionais.</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="solucoes-card text-center">
                  <h4>Softwares para Automação</h4>
                  <img src="/images/software-automacao1.png" alt="Softwares para Automação" className="img-fluid rounded-image" />
                  <p>Desenvolvimento de softwares para automação industrial e sistemas supervisórios.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="solucoes-card text-center">
                  <h4>Adequações Elétricas</h4>
                  <img src="/images/adequacao-eletrica1.png" alt="Adequações Elétricas" className="img-fluid rounded-image" />
                  <p>Adequação elétrica de equipamentos seguindo normas nacionais e internacionais.</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="solucoes-card text-center">
                  <h4>Adequações de Segurança</h4>
                  <img src="/images/adequacao-seguranca1.png" alt="Adequações de Segurança" className="img-fluid rounded-image" />
                  <p>Adequação dos circuitos de segurança de máquinas e equipamentos.</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="solucoes-card text-center">
                  <h4>Reforma de Equipamentos</h4>
                  <img src="/images/reforma-equipamentos1.png" alt="Reforma de Equipamentos Industriais" className="img-fluid rounded-image" />
                  <p>Reforma e retrofit de equipamentos industriais para aumentar a eficiência.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Home;
