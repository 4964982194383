// src/components/WhatsAppIcon.js

import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppIcon.css';

function WhatsAppIcon() {
  return (
    <a
      href="https://wa.me/5547984027016"
      className="whatsapp-icon"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp size={50} />
    </a>
  );
}

export default WhatsAppIcon;
