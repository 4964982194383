import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap'; // Importando o componente Carousel
import './Solucoes.css';

function Solucoes() {
  useEffect(() => {
    document.title = "ZFW Engenharia - Serviços de Automação, Retrofit e Projetos Elétricos";
  }, []);

  const solucoes = [
    {
      titulo: "Montagem de Painéis e Quadros Elétricos",
      descricao: "Especializados na montagem de painéis e quadros elétricos com alta qualidade e precisão.",
      imagens:  [
        "/images/painel-eletrico1.png",
        "/images/painel-eletrico2.png",
        "/images/painel-eletrico3.png"
      ]
    },
    {
      titulo: "Adequação às Normas NR10 e NR12",
      descricao: "Adequação completa de equipamentos às normas NR10 e NR12, garantindo segurança e conformidade.",
      imagens:  [
        "/images/normas-regulamentadoras1.png",
        "/images/normas-regulamentadoras2.png",
        "/images/normas-regulamentadoras3.png"
      ]
    },
    {
      titulo: "Retrofitting de Máquinas e Equipamentos Industriais",
      descricao: "Modernização de máquinas industriais, prolongando sua vida útil e melhorando o desempenho.",
      imagens:  [
        "/images/retrofitting1.png",
        "/images/retrofitting2.png",
        "/images/retrofitting3.png"
      ]
    },
    {
      titulo: "Projetos Elétricos",
      descricao: "Desenvolvimento e gerenciamento de projetos elétricos atendendo normas nacionais e internacionais.",
      imagens: [
        "/images/projeto-eletrico1.png",
        "/images/projeto-eletrico2.png",
        "/images/projeto-eletrico3.png"
      ]
    },
    {
      titulo: "Softwares para Automação",
      descricao: "Desenvolvimento de softwares customizados para automação industrial, incluindo PLC, IHM e sistemas supervisórios.",
      imagens: [
        "/images/software-automacao1.png",
        "/images/software-automacao2.png",
        "/images/software-automacao3.png"
      ]
    },
    {
      titulo: "Montagem de Painéis Elétricos de Automação",
      descricao: "Montagem especializada de painéis de baixa tensão e automação industrial.",
      imagens: [
        "/images/painel-automacao1.png",
        "/images/painel-automacao2.png",
        "/images/painel-automacao3.png"
      ]
    },
    {
      titulo: "Adequações Elétricas",
      descricao: "Adequação elétrica de equipamentos seguindo normas nacionais e internacionais.",
      imagens: [
        "/images/adequacao-eletrica1.png",
        "/images/adequacao-eletrica2.png",
        "/images/adequacao-eletrica3.png"
      ]
    },
    {
      titulo: "Adequações de Segurança",
      descricao: "Adequação dos circuitos de segurança de máquinas e equipamentos com base nas normas regulamentadoras.",
      imagens: [
        "/images/adequacao-seguranca1.png",
        "/images/adequacao-seguranca2.png",
        "/images/adequacao-seguranca3.png"
      ]
    },
    {
      titulo: "Reforma de Equipamentos Industriais",
      descricao: "Reforma e retrofit de equipamentos industriais, aumentando a eficiência e segurança.",
      imagens: [
        "/images/reforma-equipamentos1.png",
        "/images/reforma-equipamentos2.png",
        "/images/reforma-equipamentos3.png"
      ]
    },
  ];

  return (
    <div className="d-flex flex-column min-vh-100 servicos-page">
      <main className="flex-grow-1">
        <div className="container my-5">
          <div className="text-center mb-5">
            <h1>Nossas Soluções</h1>
            <p>
              Na ZFW Engenharia, desenvolvemos soluções inovadoras e personalizadas para otimizar seus processos industriais.
              Combinamos tecnologia de ponta e conhecimento especializado para garantir mais eficiência, segurança e qualidade em sua operação.
              Explore nossas soluções a seguir, conheça em detalhes como podemos transformar sua produção e atender às demandas mais exigentes do mercado, 
              sempre em conformidade com as principais normas técnicas.
            </p>
          </div>
          
          <div className="row">
            {solucoes.map((servico, index) => (
              <div key={index} className="col-md-4 mb-4">
                <div className="servico-card">
                  <Carousel interval={3000} controls={false} indicators={false}>
                    {servico.imagens.map((imagem, i) => (
                      <Carousel.Item key={i}>
                        <img
                          className="d-block w-100 img-fluid rounded"
                          src={imagem}
                          alt={`Imagem ${i + 1}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <h3>{servico.titulo}</h3>
                  <p>{servico.descricao}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Solucoes;
