import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Sobre.css'; // Certifique-se de criar este arquivo CSS para estilização

function Sobre() {
  useEffect(() => {
    document.title = "ZFW Engenharia - Projetos, Softwares Industriais, Painéis Elétricos | Integração TI x TA | Automação Industrial | Dispositivos Mecânicos Automatizados";
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100 sobre-page">
      <main className="flex-grow-1">
        <div className="container my-5">
          <div className="hero-section text-center mb-5">
            <h1>Sobre a ZFW Engenharia</h1>
            <div className="hero-graphic"></div>
          </div>
          
          <div className="content-section">
            <div className="row">
              <div className="col-md-6">
                <h2>Nossa História</h2>
                <p className="lead">
                  A ZFW Engenharia é uma empresa jovem e inovadora, comprometida em oferecer soluções de alta qualidade em automação industrial e engenharia elétrica. Com sede em Joinville/SC, desde 2005, nossa missão é superar as expectativas dos clientes, entregando resultados com excelência e precisão.
                </p>
                <p>
                  Nossos serviços abrangem desde a comercialização de componentes e dispositivos até a implementação de sistemas completos de automação. Atuamos com um portfólio diversificado de clientes, tanto nacionais quanto internacionais, sempre priorizando o suporte técnico especializado e um atendimento que vai além do padrão.
                </p>
              </div>
              <div className="col-md-6 text-center">
                <img src="/images/galpao.png" alt="Ilustração da empresa" className="img-fluid rounded" />
              </div>
            </div>
          </div>

          <div className="mission-vision-values mt-5">
            <div className="row">
              <div className="col-md-4 text-center mb-4">
                <div className="box">
                  <h2>Missão</h2>
                  <p>Fornecer soluções inovadoras e personalizadas que atendam às necessidades dos nossos clientes, garantindo qualidade superior e total satisfação.</p>
                </div>
              </div>
              <div className="col-md-4 text-center mb-4">
                <div className="box">
                  <h2>Visão</h2>
                  <p>Ser reconhecida como referência em inovação, qualidade e confiabilidade no setor de automação industrial e engenharia elétrica, tanto no Brasil quanto no exterior.</p>
                </div>
              </div>
              <div className="col-md-4 text-center mb-4">
                <div className="box">
                  <h2>Valores</h2>
                  <ul className="valores-list">
                    <li>Ética e Integridade em todas as relações</li>
                    <li>Compromisso com a Excelência</li>
                    <li>Confiabilidade e Transparência</li>
                    <li>Foco no Cliente e na Satisfação</li>
                    <li>Inovação e Melhoria Contínua</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Sobre;
