import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from './Header.module.css';
import logo from '../assets/logo_zfw_5.png';

function Header() {
  return (
    <header className={styles.header}>
      <Navbar expand="lg" className={`py-3 ${styles.navbar}`}>
        <div className="container d-flex align-items-center justify-content-between">
          {/* Logotipo da ZFW com design arrojado */}
          <Navbar.Brand href="/" className={styles.logoContainer}>
            <img src={logo} alt="ZFW Engenharia" className={styles.logo} />
          </Navbar.Brand>
          
          {/* Toggle Button para Navegação em Dispositivos Móveis */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          
          {/* Navegação Colapsada */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto d-flex justify-content-between align-items-center w-100">
              <NavLink
                to="/sobre"
                className={`nav-link ${styles['nav-link']}`}
                activeClassName={styles['active']}
              >
                Sobre
              </NavLink>
              <NavLink
                to="/solucoes"
                className={`nav-link ${styles['nav-link']}`}
                activeClassName={styles['active']}
              >
                Soluções
              </NavLink>
              <NavLink
                to="/contato"
                className={`nav-link ${styles['nav-link']}`}
                activeClassName={styles['active']}
              >
                Contato
              </NavLink>
              <NavLink
                to="/acesso-restrito"
                className={`nav-link ${styles['nav-link']}`}
                activeClassName={styles['active']}
              >
                Acesso{"\n"}Restrito
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className={styles.bannerBorders}>
        <div className={styles.blueBorder}></div>
        <div className={styles.greenBorder}></div>
      </div>
    </header>
  );
}

export default Header;
