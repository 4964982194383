import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Projetos.css';

function Projetos() {
  useEffect(() => {
    document.title = "ZFW Engenharia - Projetos de Automação Industrial, Retrofit, e Engenharia Elétrica";
  }, []);

  const projetos = [
    {
      titulo: "Automação de Linha de Produção",
      descricao: "Desenvolvimento de sistemas automatizados para linhas de produção, integrando PLCs, sensores, e IHMs.",
      imagem: "/images/automacao-producao.png"
    },
    {
      titulo: "Retrofit de Máquinas Industriais",
      descricao: "Modernização de máquinas com a substituição de componentes obsoletos e integração de novos sistemas de controle.",
      imagem: "/images/retrofit-maquinas.png"
    },
    {
      titulo: "Montagem de Painéis Elétricos",
      descricao: "Design e montagem de painéis elétricos para controle e distribuição de energia em instalações industriais.",
      imagem: "/images/montagem-paineis.png"
    },
    {
      titulo: "Adequação às Normas NR10 e NR12",
      descricao: "Adequação de equipamentos e instalações às normas NR10 e NR12, garantindo segurança e conformidade.",
      imagem: "/images/adequacao-nr.png"
    },
    {
      titulo: "Integração de Sistemas Supervisórios (SCADA)",
      descricao: "Desenvolvimento de sistemas SCADA para monitoramento e controle de processos industriais em tempo real.",
      imagem: "/images/scada.png"
    },
    {
      titulo: "Projetos de Engenharia Elétrica",
      descricao: "Desenvolvimento e gerenciamento de projetos elétricos completos, desde a concepção até a execução.",
      imagem: "/images/engenharia-eletrica.png"
    },
    {
      titulo: "Automação de Processos",
      descricao: "Automatização de processos industriais para melhorar a eficiência, reduzir custos e aumentar a qualidade.",
      imagem: "/images/automacao-processos.png"
    },
    {
      titulo: "Desenvolvimento de Softwares Personalizados",
      descricao: "Criação de softwares específicos para automação e controle, atendendo às necessidades dos clientes.",
      imagem: "/images/software-personalizado.png"
    },
    {
      titulo: "Controle para Linhas de Embalagem",
      descricao: "Sistemas de controle para otimização de linhas de embalagem, garantindo precisão e eficiência.",
      imagem: "/images/controle-embalagem.png"
    }
  ];

  return (
    <div className="d-flex flex-column min-vh-100 projetos-page">
      <main className="flex-grow-1">
        <div className="container my-5">
          <div className="text-center mb-5">
            <h1>Nossos Projetos</h1>
            <p>Explore alguns dos principais projetos que realizamos na ZFW Engenharia, abrangendo diversas áreas da automação industrial, engenharia elétrica, e modernização de máquinas.</p>
          </div>
          
          <div className="row">
            {projetos.map((projeto, index) => (
              <div key={index} className="col-md-4 mb-4">
                <div className="projeto-card">
                  <img src={projeto.imagem} alt={projeto.titulo} className="img-fluid mb-3"/>
                  <h3>{projeto.titulo}</h3>
                  <p>{projeto.descricao}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Projetos;
