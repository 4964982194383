import React, { useEffect } from 'react';
import { FaWhatsapp, FaMapMarkerAlt, FaBuilding, FaCity, FaPhone, FaMap, FaWaze } from 'react-icons/fa'; // Importa ícones

function Contato() {
  useEffect(() => {
    document.title = "ZFW Engenharia - Joinville SC | Líder Automação Industrial | Painéis Elétricos | Retroffiting Máquinas";
  }, []);

  return (
    <div className="container mt-5">
      <div className="text-center mb-5">
        <h3>Fale conosco diretamente pelo WhatsApp</h3>
        <a 
          href="https://wa.me/5547984027016" 
          className="btn btn-lg btn-success d-inline-flex align-items-center justify-content-center" 
          style={{ padding: '15px 30px', fontSize: '1.25rem', marginTop: '20px' }}
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FaWhatsapp style={{ marginRight: '10px' }} />
          Entre em contato via WhatsApp
        </a>
      </div>

      <h2>Ou preencha o formulário abaixo</h2>
      <form>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Nome</label>
          <input type="text" className="form-control" id="name" placeholder="Seu nome" required />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input type="email" className="form-control" id="email" placeholder="nome@exemplo.com" required />
        </div>
        <div className="mb-3">
          <label htmlFor="subject" className="form-label">Assunto</label>
          <input type="text" className="form-control" id="subject" placeholder="Assunto do contato" required />
        </div>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">Mensagem</label>
          <textarea className="form-control" id="message" rows="5" placeholder="Escreva sua mensagem aqui" required></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Enviar</button>
      </form>


      <hr />
      

      <div className="mt-4 row">
        <div className="col-md-6">
          <h3>Nosso Endereço</h3>
          <div className="address-modern">
            <p><FaMapMarkerAlt className="me-2" /> Rua República da China, 80</p>
            <p><FaBuilding className="me-2" /> Bairro Floresta</p>
            <p><FaCity className="me-2" /> Joinville, Santa Catarina</p>
            <p><FaPhone className="me-2" /> <a href="tel:+554734737029">+55 47 3473 7029</a></p>
          </div>
        </div>
        <div className="col-md-6 text-center">
          <h3>Localização</h3>
          <a 
            href="https://www.google.com/maps?q=Rua+Republica+da+China+80,+Joinville,+SC" 
            target="_blank" 
            rel="noopener noreferrer"
            className="btn btn-outline-primary d-inline-flex align-items-center justify-content-center"
            style={{ padding: '15px 30px', fontSize: '1.25rem', marginTop: '20px', textDecoration: 'none' }}
          >
            <FaMap style={{ marginRight: '10px' }} />
            Ver no Google Maps
          </a>

          <div className="mt-3">
            <a 
              href="https://www.google.com/maps/dir/?api=1&destination=-26.33292940533216,-48.851099343919095" 
              target="_blank" 
              rel="noopener noreferrer"
              className="btn btn-info d-inline-flex align-items-center justify-content-center"
              style={{ padding: '10px 20px', fontSize: '1rem', marginTop: '10px', textDecoration: 'none' }}
            >
              <FaMap style={{ marginRight: '10px' }} />
              Navegar com Google Maps
            </a>
            <a 


              href="https://waze.com/ul?ll=-26.33292940533216,-48.851099343919095&navigate=yes" 
              target="_blank" 
              rel="noopener noreferrer"
              className="btn btn-primary d-inline-flex align-items-center justify-content-center"
              style={{ padding: '10px 20px', fontSize: '1rem', marginTop: '10px', textDecoration: 'none', marginLeft: '10px' }}
            >
              <FaWaze style={{ marginRight: '10px' }} />
              Navegar com Waze
            </a>
          </div>

          <div style={{ marginTop: '20px' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3539.7806730180393!2d-48.88440868493792!3d-26.30420078338486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94deafbfa5e5f983%3A0x515fcb5fef3c8f67!2sR.%20Rep%C3%BAblica%20da%20China%2C%2080%20-%20Floresta%2C%20Joinville%20-%20SC%2C%2089222-260!5e0!3m2!1spt-BR!2sbr!4v1693136495034!5m2!1spt-BR!2sbr"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Localização ZFW Engenharia"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contato;
