import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Solucoes from './pages/Solucoes';
import Projetos from './pages/Projetos';
import Contato from './pages/Contato';
import Header from './components/Header';
import Footer from './components/Footer';
import WhatsAppIcon from './components/WhatsAppIcon';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Certifique-se de que este arquivo CSS exista

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <main className="flex-grow-1 d-flex flex-column">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/solucoes" element={<Solucoes />} />
            <Route path="/projetos" element={<Projetos />} />
            <Route path="/contato" element={<Contato />} />
          </Routes>
        </main>
        <Footer />
        <WhatsAppIcon /> {/* Ícone do WhatsApp fixo no canto inferior direito */}
      </div>
    </Router>
  );
}

export default App;
